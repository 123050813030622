<template>
  <div>
    <loading :active.sync="isLoading" 
      :can-cancel="false" 
      :color="'#aa945b'"
      :is-full-page="fullPage">
    </loading>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <b>Add New User</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
              <CCol col="4">
                <CInput label="User Name" placeholder="Enter user name" v-model="new_user.name"/>
              </CCol>
              <CCol col="4">
                <CInput label="User Email" placeholder="Enter user email" v-model="new_user.email"/>
              </CCol>
              <CCol col="4">
                  <label>Role</label>
                  <select class="form-control"  v-model="new_user.role_id">
                      <option :value="null">-- Please select an option --</option>
                      <template v-for="role in user.roles">
                          <option :value="role.id">{{role.name}}</option>
                      </template>
                  </select>
              </CCol>
          </CRow>

        </CCardBody>
        <CCardFooter>
            <button type="button" class="btn btn-success mr-3" @click="addUser">Add A User</button>
            <button type="button" class="btn btn-danger"  @click="reset">Reset</button>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
  <CRow v-if="auth.user && auth.user.role_id == 1 && user.all.length > 0" style="padding: 10px">
    <CCol sm="12">  
      <CCard>
        <CCardHeader>Users</CCardHeader>
          <CCardBody>
              <CDataTable 
                  :items="user.all"
                  :fields="fields"
                  :noItemsView='{ noResults: "No Users Found", noItems: "No Users Found" }'
                  :tableFilter='{ label : "Filter", placeholder:"Type to Search..." }'
                  hover
                  sorter>
                  <template #role_id="{item, index}">
                      <td>
                        {{getUserRole(item)}}
                      </td>

                  </template>
                
                  <template #action="{item, index}">
                      <td>
                          <font-awesome-icon icon="edit" style="color:green;" @click="editUser(item)" class="mr-4"/>
                          <font-awesome-icon icon="trash-alt" style="color:red;" @click="confirmdeleteUser(item)"/>

                      </td>
                  </template>
              </CDataTable>
          </CCardBody>
      </CCard>
    </CCol>

  </CRow>
    <CModal
      title="Confirmation"
      color="success"
      centered
      :show.sync="deleteConfirmationModal"
    >
      Are you sure you want to delete a user?
      <template slot="footer">
        <CButton color="secondary">Cancel</CButton>
        <CButton color="success" @click="deleteUser">OK</CButton>
      </template>
    </CModal>

    <CModal
      :title="edit_user_title"
       centered
      :show.sync="editUserModal"
    >
      <CRow>
        <CCol sm="12">
          <CInput label="Name" type="text" v-model="selected_user.name"></CInput>
        </CCol>
        <CCol sm="12">
          <CInput label="Email" type="email" v-model="selected_user.email"></CInput>
        </CCol>
        <CCol sm="12">
          <label>Role</label>
            <select class="form-control"  v-model="selected_user.role_id">
                <option :value="null">-- Please select an option --</option>
                <template v-for="role in user.roles">
                    <option :value="role.id">{{role.name}}</option>
                </template>
            </select>
        </CCol>
      </CRow>
      <template slot="footer">
        <CButton color="secondary" @click="cancelUserModal">Cancel</CButton>
        <CButton color="success" @click="saveUser">Save</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'Users',
  data () {
    return {
      deleteConfirmationModal:false,
      editUserModal:false,
      fullPage:true,
      isLoading:false,
      fields:[
        {key: 'name', _style:'min-width:30px;', label:'Name'},
        {key: 'email', _style:'min-width:40px;', label:'Email'},
        {key: 'role_id', _style:'min-width:40px;', label:'Role'},
        {key: 'action', _style:'min-width:30px;', label:'', sorter: false, },
      ],
      permissions: [
        { value: "english", text: "English Only" },
        // { value: "chinese", text: "Chinese Only" },
        { value: "mathematics", text: "Mathematics Only" },
        { value: "science", text: "Science Only" },
        { value: "all", text: "All Subjects" }
      ],
      selected_user:{},
      edit_user_title:"Edit User",
      new_user:{
        email:'',
        role_id: null,
        permission: null
      },
      delete_user:{},
      activePage: 1
    }
  },
  async mounted(){
      this.isLoading = true;
      await this.$store.dispatch("auth/fetchUser");
      await this.$store.dispatch("user/fetchRoles");
      // await this.$store.dispatch("course/fetchPermission", 2);
      await this.$store.dispatch("user/fetchAllUsers");
      this.isLoading = false;
  },
  computed: {
    ...mapState(["auth"]),
    ...mapState(["user"]),
    ...mapState({
        userCount: state => state.user.all.length
    })
  },
  methods:{
      getUserRole(user){
        let role = ""

        var result = this.user.roles.filter(obj => {
          return obj.id === user.role_id
        })

        if(result){
            role = result[0].name
          }


        return role
      },
      getUserPermission(user){
        let permission = ""
        
        var result = this.permissions.filter(obj => {
          return obj.value === user.permission
        })

        if(result){
          permission = result[0].text
        }

        return permission
      },
      editUser(user){
          this.selected_user = user
          this.edit_user_title = "Edit User - (" + user.name + ")"
          this.editUserModal = true
      },

      async saveUser(){
            this.isLoading = true;
            await this.$store
                .dispatch("user/updateUser", {
                    id: this.selected_user.id,
                    name: this.selected_user.name,
                    email: this.selected_user.email,
                    role_id: this.selected_user.role_id
                })
                .then(() => {
                    this.isLoading = false;
                    console.log(this.user)
                    this.editUserModal = false
                })
                .catch(error => {
                if (error.response) {
                    Vue.notify({
                    group: "notify",
                    type: "error",
                    text: error.response.data.error.message,
                    max: 5,
                    duration: 5000,
                    speed: 300
                    });
                } else {
                    console.log("Problem submitting New Post", error);
                }
                });
            this.isLoading = false;
            this.editTagModal = false
        },
      cancelUserModal(){
        this.selected_user = {}
        this.editUserModal = false
      },
      confirmdeleteUser(user){
        this.delete_user = user
        this.deleteConfirmationModal = true
      },
      async deleteUser() {
         
        if(this.delete_user.id) {
            this.deleteConfirmationModal = false
            this.isLoading = true;
            this.user.all = [];
            await this.$store.dispatch("user/deleteUser", this.delete_user.id);
            this.isLoading = false;
        }

      },
      async toggleAdmin(index) {
        this.isLoading = true;
        this.user.all[index].admin = !this.user.all[index].admin;
        await this.$store.dispatch("user/updateUser", this.user.all[index]);
        this.isLoading = false;
      },
      async addUser() {
          this.isLoading = true;
          if(this.new_user.email)
          {
              await this.$store.dispatch("user/addUser", this.new_user);
              this.reset()
          }
          else
          {
            alert("Please fill in required details")
          }
          this.isLoading = false;
      },
      reset() {
        this.new_user = {
          name:"",
          email: "",
          role_id: null,
          permission: null
        };
    },

  }
}
</script>
